<template>
    <div class="body">
        <div class="form-content w-100 p-0">
            <h2 class="text-center title mb-1 font_helvetica_neue75_bold">
                Política de Privacidad
            </h2>
            <div class="mb-1">
                <div class="font_helvetica_neue55_roman text-dark scroll policy">
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">ALCANCE</h6>
                    <p class="font_times_new_roman font-0_75 text-justify mb-1">
                        La presente política resulta aplicable a las bases de datos, registros y/o archivos que contengan datos personales que sean objeto de tratamiento por parte de A.W. FABER CASTELL PERUANA S.A. (en adelante FABER-CASTELL), con RUC 20100050359, con domicilio en Av. La Molina 161, Ate, Lima - Perú, en su calidad de responsable del tratamiento de tales datos, los cuales han sido recolectados de forma libre, voluntaria, expresa, inequívoca e informada, a través de la plataforma del aplicativo móvil denominada: “CREDIFABER”
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">LEGISLACIÓN APLICABLE</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                       Esta Política de Protección de Datos Personales se rige por lo dispuesto en la Ley 29733 – Ley de Protección de Datos Personales, su Reglamento, su Directiva de Seguridad y cualquier otra normativa vigente y aplicable.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">DEFINICIONES:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                        <span class="font_helvetica_neue75_bold">Autorización: </span> previo, expreso e informado de quien se declara titular o dueño de la información que es considerada como susceptible o apropiada para ser llevada al Tratamiento de Datos Personales.
                       <br>
                       <span class="font_helvetica_neue75_bold">Aviso de Privacidad: </span>Comunicación verbal o escrita generada por el responsable, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del tratamiento que se pretende dar a los datos personales.
                       <br>
                       <span class="font_helvetica_neue75_bold">Banco de Datos:</span> Conjunto organizado de datos personales, automatizado o no, independientemente del soporte, sea este físico, magnético, digital, óptico u otros que se creen, cualquiera fuere la forma o modalidad de su creación, formación, almacenamiento, organización y acceso.
                       <br>
                       <span class="font_helvetica_neue75_bold">Datos Personales:</span> Toda información sobre una persona natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados.
                       <br>
                       <span class="font_helvetica_neue75_bold">Datos Sensibles:</span> Datos personales constituidos por los datos biométricos que por sí mismos pueden identificar al titular; datos referidos al origen racial y étnico; ingresos económicos, opiniones o convicciones políticas, religiosas, filosóficas o morales; afiliación sindical; e información relacionada a la salud o a la vida sexual
                       <br>
                       <span class="font_helvetica_neue75_bold">Encargado del Tratamiento:</span> Toda persona natural, persona jurídica de derecho privado o entidad pública que sola o actuando juntamente con otra realiza el tratamiento de los datos personales por encargo del titular del banco de datos personales.
                       <br>
                       <span class="font_helvetica_neue75_bold">Responsable del Tratamiento:</span> Persona natural o jurídica, pública o privada, que decida sobre el ingreso, salida de información o actualización de la base de datos y/o el tratamiento de los datos.
                       <br>
                       <span class="font_helvetica_neue75_bold">Términos y Condiciones:</span> marco general en el cual se establecen las condiciones para quienes sean participantes de actividades comerciales, afines o simplemente tratantes con el responsable
                       <br>
                       <span class="font_helvetica_neue75_bold">Titular de datos personales:</span> Persona natural a quien corresponde los datos personales
                       <br>
                       <span class="font_helvetica_neue75_bold">Tratamiento:</span> Cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales
                       <br>
                       <span class="font_helvetica_neue75_bold">Transferencia:</span>Toda transmisión, suministro o manifestación de datos personales, de carácter nacional o internacional, a una persona jurídica de derecho privado, a una entidad pública o a una persona natural distinta del titular de datos personales.
                       <br>
                       <span class="font_helvetica_neue75_bold">Flujo Transfronterizo:</span> Transferencia internacional de datos personales a un destinatario situado en un país distinto al país de origen de los datos personales, sin importar el soporte en que estos se encuentren, los medios por los cuales se efectuó la transferencia ni el tratamiento que reciban. 
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">TRATAMIENTO:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                       FABER-CASTELL, actuando en calidad de responsable del tratamiento de datos personales, para el adecuado desarrollo de sus actividades en su plataforma de aplicativo móvil, así como para el fortalecimiento de sus relaciones con terceros (clientes), recolecta, almacena, conserva, utiliza, suprime, y demás permitidos legalmente, datos correspondientes a personas con las que mantiene o ha mantenido una relación comercial o contractual.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">FINALIDAD:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify">
                       FABER-CASTELL tratará los datos personales obtenidos en su plataforma de aplicativo móvil con la finalidad de recopilar información para:
                       <br>
                       <ul style="padding:revert" class="font-0_75 mb-1">
                           <li>Recopilar información para la obtención de indicadores y estadísticas de manejo administrativo de FABER-CASTELL o a ser presentado antes las autoridades que puedan requerir de dicha data. </li>
                           <li>Atender la solicitud de información, actualización de datos, ejercicio de derechos ARCO (Acceso, Rectificación, Cancelación y Oposición), entre otros.</li>
                           <li>Facilitar el uso del aplicativo móvil “CREDIFABER” de FABER-CASTELL.</li>
                           <li>Velar por la seguridad e integridad de dicho aplicativo y su contenido.</li>
                           <li>Compartir, difundir y recibir información proveniente de encuestas y/o registros o formularios, sean físicos o virtuales.</li>
                           <li>Cualquier otro que guarde relación directa con la actividad comercial o administrativa de FABER-CASTELL en su plataforma digital en relación con los titulares de los datos personales.</li>
                       </ul>
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">TRANSFERENCIA DE DATOS PERSONALES Y FLUJO TRANSFRONTERIZO:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                        La recopilación de datos personales podrá realizarse a través de servicios brindados por terceros, domiciliados en Perú o en el extranjero, en cuyo caso se efectuará la respectiva transferencia de datos. En tal sentido, FABER-CASTELL declara realizar la recopilación de los datos personales antes indicados a través de la empresa XXXXX, con domicilio en XXXXX, cuyos servidores para almacenar tal información son suministrados por XXXXX, con domicilio en XXXXX.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">PERÍODO DE CONSERVACIÓN DE LOS DATOS PROPORCIONADOS:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                        FABER-CASTELL conservará y tratará los datos personales proporcionados en tanto la relación comercial o contractual con los titulares de los datos personales mantenga vigencia y/o mientras los titulares de los mismos no soliciten formalmente su cancelación.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">DERECHOS DE LOS TITULÁRES DE LOS DATOS PERSONALES - DERECHOS ARCO:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify">
                       Las personas cuyos datos personales sean objeto de tratamiento por parte de FABER-CASTELL, tienen los siguientes derechos ARCO, los cuales pueden ejercer en cualquier momento:
                        <br>
                       <ul style="padding:revert" class="font-0_75 mb-1">
                           <li><span class="font_helvetica_neue75_bold">Derecho de información:</span>Conocer los datos personales sobre los cuales FABER-CASTELL, está realizando el tratamiento. De igual manera, el titular puede solicitar en cualquier momento, que sus datos sean actualizados o rectificados. </li>
                           <li><span class="font_helvetica_neue75_bold">Derecho de Acceso:</span> prueba de la autorización otorgada a FABER-CASTELL, para el tratamiento de sus datos personales.</li>
                           <li>Acceder de forma gratuita a sus datos personales objeto de tratamiento.</li>
                           <li>Ser informado por FABER-CASTELL, previa solicitud, respecto del uso que ésta le da sus datos personales.</li>
                           <li>Presentar ante Ministerio de Justicia las quejas por infracciones a lo dispuesto en la Ley de Protección de Datos Personales, previo trámite de consulta o requerimiento ante FABER-CASTELL.</li>
                           <li>Derecho de cancelación, supresión u oposición: solicitar la rectificación, cancelación y/o supresión de sus datos personales y/o revocar la autorización otorgada para el tratamiento de los mismos, mediante la presentación de una solicitud, de acuerdo con los procedimientos establecidos en la presente Política. En caso de revocatoria de la autorización, el titular no podrá acceder al servicio proporcionado por FABER-CASTELL, en la medida que tal información resulte imprescindible para el funcionamiento de la plataforma móvil.</li>
                       </ul>
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">CONSENTIMIENTO:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                       La presente política se pone a disposición de los clientes de FABER-CASTELL de forma previa, expresa e informada a la emisión de su autorización. Para tal efecto, el consentimiento se manifestará a través de la plataforma de aplicativo móvil de FABER-CASTELL. 
                       <br>
                       A su vez, FABER-CASTELL informa que, una vez ingresados los datos personales en la plataforma móvil, estos serán registrados en el Banco de Datos Personales denominado “CLIENTES”.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">PROCEDIMIENTO PARA ATENCIÓN Y RESPUESTA A PETICIONES, CONSULTAS, QUEJAS Y RECLAMOS DE LOS TITULÁRES DE DATOS PERSONALES</h6>
                     <p class="font_times_new_roman font-0_75 text-justify">
                       Los titulares de los datos personales tratados por FABER-CASTELL podrán ejercer, en cualquier momento, su derecho a conocer, actualizar, rectificar y suprimir información y/o revocar la autorización concedida. Para tal efecto, podrán cursar una comunicación a FABER-CASTELL a través de cualquiera de los siguientes medios:
                        <br>
                       <ul style="padding:revert" class="font-0_75 mb-1">
                           <li>Comunicación escrita dirigida a A.W. FABER-CASTELL PERUANA S.A., Av. La Molina N° 161, Ate-Lima-Perú, con el Asunto: “DATOS PERSONALES”</li>
                           <li>Solicitud presentada al correo electrónico: XXXXX@faber-castell.com.pe</li>
                       </ul>
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">CONFIDENCIALIDAD:</h6>
                     <p class="font_times_new_roman font-0_75 text-justify mb-1">
                      La información y los datos personales proporcionados a FABER-CASTELL mediante el aplicativo móvil “CREDIFABER” serán tratados con absoluta confidencialidad. FABER-CASTELL se compromete a guardar el secreto profesional sobre los mismos, garantizando su custodia e integridad implementando las medidas de seguridad necesarias.
                    </p>
                    <h6 class="font_helvetica_neue75_bold text-primary text-sub">SEGURIDAD DE LA INFORMACIÓN Y DE LOS DATOS PERSONALES</h6>
                     <p class="font_times_new_roman font-0_75 text-justify">
                        FABER-CASTELL, en estricta aplicación del Principio de Seguridad en el Tratamiento de Datos Personales, proporcionará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. La obligación y responsabilidad de FABER-CASTELL se limita a disponer de los medios adecuados para este fin.
                        <br>
                        FABER-CASTELL exigirá a los proveedores de servicios que contrata, la adopción y cumplimiento de las medidas técnicas, humanas y administrativas adecuadas para la protección de los Datos Personales en relación con los cuales dichos proveedores actúen como encargados.
                        <br>
                        FABER-CASTELL no se responsabiliza por cualquier consecuencia derivada de fallas técnicas o ingresos indebidos por parte de terceros a la Base de Datos o Archivos en los que reposan los datos personales objeto de tratamiento. Sin perjuicio de ello, desplegará su mayor esfuerzo y compromiso para la contratación de servicios y soporte que repelan o eviten dichas intromisiones no autorizadas.
                    </p>

                </div>
            </div>
        
        
        </div>
    </div>
</template>


<script>
export default {
    props:{
        close:{
            type:Function,
        }
    }
}
</script>