<template>
  <header class="header my-5">
    <h1 class="font_helvetica_neue75_bold" v-if="type === 'auth' || type === 'admin'">
      {{ title }}
    </h1>
    <h3 class="font_helvetica_neue75_bold" v-if="type === 'password'">
      {{ title }}
    </h3>

    <p class="font_helvetica_neue55_roman" v-if="type === 'auth'">
      {{ description }}
      <router-link :to="{ name: route }" @click="gtmRegister()"> {{ linkName }} </router-link>
    </p>
    <h3 class="font_helvetica_neue55_roman" v-if="type === 'admin'">
      {{ description }}
    </h3>
     <h4 class="font_helvetica_neue55_roman mb-2" v-if="type === 'password'">
      {{ description }}
    </h4>
  </header>
</template>

<script>
import gaFunction from '@/utils/ga/gaFunction';
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    route: { type: String},
    linkName: { type: String },
    type: { type : String , default : 'auth'}
  },
  methods:{
    gtmRegister(){
      if(this.linkName == 'Regístrate'){
      gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Link de registro'});
      }
      else{
        gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Link al login'});
      }
    }
  }
};
</script>
