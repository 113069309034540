export default (a,b,c) => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-249712648-1');
    console.log(a,b,c)
    gtag(a,b,c);
    gtag('event', 'page_view', {
        page_title: '<Page Title>',
        page_location: window.location,
        page_path: '<Page Path>',
        send_to: '<GA_MEASUREMENT_ID>'
    })
}