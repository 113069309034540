<template>
    <div class="modal" :id="type_ !== 'modal' ? 'privacy-modal' :''">
        <div class="modal-content" :class="type_==='modal' ? ' w-md-50' : 'modal-policy'">
            <div class="icon">
                <i class="fas fa-times text-primary close-modal" @click="close"></i>
            </div>
            <div v-if="typeModal === 'result'">
                <div class="header-modal header-images mt-2">
                    <img class="w-auto" :src="imageModal" alt="Email éxitoso" title="Email éxitoso">
                </div>
                <div class="body-modal text-center p-0 m-auto">
                    <div class="form-content p-0 mb-3 mx-1">
                        <h2 class="font_helvetica_neue75_bold mt-1">{{title}}</h2>
                        <p class="mb-1 mt-1 font_helvetica_neue55_roman description-modal">
                            {{description}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="d-flex buttons gap-1 justify-center" v-if="withButtons">
                <div :key="index" v-for="(button,index) in buttons">
                    <button class="button" v-bind:class="button.class" @click="$router.push(button.route)">
                        {{button.text}}
                    </button>
                </div>
                
                
            </div>

            <div v-if="typeModal !== 'result'">
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props:{
        typeModal:{
            type:String,
            required:true
        },
        description:{
            type:String,
        },
        type:{
            type:String
        },
        close:{
            type:Function
        },
        imageModal:{
            type:String,
            default:"/images/decorations/success.png",
        },
        title:{
            type:String
        },
        withButtons:{
            type:Boolean
        },
        buttons:{
            type:Array
        },
        type_:{
            type:String,
            default:"modal"
        },
    },

}
</script>