<template>
  <form class="body mt-2" @submit.prevent="registerByCodeUser">
    <ModalLayout :isShow="isShow">
      <ModalContainer
        :close="showOrClose" 
        typeModal="text"
        type_="no_modal"
        >
        <PrivacyPolicy  :close="showOrClose"/>
      </ModalContainer>
    </ModalLayout>
    <div class="form-control ml-0 mw-auto">
      <label for="secury_code">{{ $t('signUp.clientCode.form.securityCodeLabel') }}</label>
      <input
        type="text"
        name="secury_code"
        :placeholder="$t('signUp.clientCode.form.securityCodePlaceholder')"
        v-model="userForm.security_code"
        required
      />
    </div>

    <div class="form-control mt-2 ml-0 mw-auto">
      <label for="email_address">{{ $t('signUp.clientCode.form.emailLabel') }}</label>
      <input
        type="email"
        name="email_address"
        :placeholder="$t('signUp.clientCode.form.emailPlaceholder')"
        v-model="userForm.email"
        required
      />
      <span class="text-example d-block font_helvetica_neue55_roman">
        <!-- {{ $t('signUp.clientCode.form.emailExample') }} -->
      </span>
    </div>

    <div class="form-control form-control-check mt-1 mw-auto">
      <input
        type="checkbox"
        name="terms"
        v-model="userForm.terms_and_conditions"
      />
      <label class="label-small d-flex">
        {{ $t('signUp.clientCode.form.termsLabel') }}
        <a class="underline" @click="isShow = !isShow">{{ $t('signUp.clientCode.form.privacyPolicyLink') }}</a>
      </label>
    </div>
    <LoadingComponent v-if="isLoading" />

    <MessageComponent
      v-if="!isLoading && messageForm.message"
      :message="messageForm.message"
      :messageType="messageForm.messageType"
      :classes="['text-center', 'mt-1', 'form-message']"
    />
    <div class="form-actions mt-2">
      <button type="submit" class="btn btn-secondary">{{ $t('signUp.clientCode.form.submitButton') }}</button>
    </div>
    
  </form>
</template>


<script>
import { LoadingComponent, MessageComponent } from '@/components';
import useRegisterByCode from '@/modules/auth/composables/register/useRegisterByCode';
import useModal from '@/composables/useModal';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import PrivacyPolicy from '@/modules/auth/components/register/modals/PrivacyPolicy';

export default {
  props:{
    show:{
      type:Boolean,
      required:true
    }

  },
  components: {
    LoadingComponent,
    MessageComponent,
    ModalContainer,
    ModalLayout,
    PrivacyPolicy
  },
  setup() {
    return {
      ...useRegisterByCode(),
      ...useModal()
    };
  },
};
</script>


