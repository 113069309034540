import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import isValidEmail from '@/utils/validations/isValidEmail';
import isNumber from '@/utils/validations/isNumber';

const useRegisterByPersonalData = () => {
  const router = useRouter();
  const store = useStore();

  const isLoading = ref(false);
  const isShowBtn = ref(false);
  const userForm = ref({
    company_name: '',
    name: '',
    ruc: '',
    cellphone: '',
    email: '',
    address: '',
    terms_and_conditions: false,
  });
  const messageForm = ref({
    message: '',
    messageType: '',
  });

  const resetValues = () => {
    messageForm.value.message = '';
    messageForm.value.messageType = '';
  };

  const resetFields = () => {
    userForm.value.company_name = '';
    userForm.value.name = '';
    userForm.value.ruc = '';
    userForm.value.cellphone = '';
    userForm.value.email = '';
    userForm.value.address = '';
    userForm.value.terms_and_conditions = false;
  };

  const showMessage = (message, messageType) => {
    isLoading.value = false;
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
  };

  const registerByPersonalDataUser = async () => {
    isLoading.value = true;
    resetValues();

    const acceptTerms = userForm.value.terms_and_conditions ? 1 : 0;

    const ruc = userForm.value.ruc + '';
    const hasPhone = userForm.value.cellphone !== '';
    const phone = userForm.value.cellphone + '';

    if (!isNumber(userForm.value.ruc)) {
      showMessage('Ingrese un RUC válido', 'error');
      return;
    } else if (ruc.length < 11 || ruc.length > 11) {
      showMessage('Ingrese un RUC válido de 11 dígitos', 'error');
      return;
    } else if (
      hasPhone &&
      (!isNumber(userForm.value.cellphone) || !phone.startsWith('9'))
    ) {
      showMessage('Ingrese un número celular válido', 'error');
      return;
    } else if ((hasPhone && phone.length < 9) || phone.length > 9) {
      showMessage('Ingrese un celular válido de 9 dígitos', 'error');
      return;
    } else if (!isValidEmail(userForm.value.email)) {
      showMessage('Ingrese un correo válido', 'error');
      return;
    } else if (acceptTerms === 0) {
      showMessage('Acepte los términos y condiciones', 'error');
      return;
    }

    if (hasPhone) userForm.value.cellphone = phone;

    // const { success } = await store.dispatch('auth/checkRuc', { ruc });

    // if (success) {
      const { success, message } = await store.dispatch(
        'auth/registerByPersonalData',
        { ...userForm.value, terms_and_conditions: acceptTerms }
      );

      const messageType = success ? 'success' : 'error';
      if (success) {
        localStorage.setItem('email', userForm.value.email);
        resetFields();
        resetValues();
        router.push({ name: 'email-confirmation' });
      } else if (!success) {
        if (
          message ===
          'Ya contamos con tus datos, regístrate con la opción de Código de cliente.'
        ) {
          isShowBtn.value = true;
        }
        showMessage(message, messageType);
      }
    // } else {
    //   showMessage('El Ruc ingresado es inválido', 'error');
    // }
  };
  return {
    registerByPersonalDataUser,
    isLoading,
    userForm,
    messageForm,
    isShowBtn,
  };
};

export default useRegisterByPersonalData;
